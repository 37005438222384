<template>
    <div class="iframe-container">
        <iframe :src="url" frameborder="0" width="100%" height="600px" allowfullscreen></iframe>
    </div>
</template>

<script>
export default {
    data() {
        return {
            url: "https://manage.zesthub.com/document/deployment/production/wms/view" 
        };
    }
};
</script>

<style scoped>
.iframe-container {
    width: 100%;
    display: flex;
    justify-content: center;
}
</style>